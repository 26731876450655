import { restartableTask, task, timeout } from 'ember-concurrency';

import { A } from '@ember/array';
import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { isBlank } from '@ember/utils';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const DEBOUNCE_MS = 470;
export default class MgmtSubscriptionsLoaderComponent extends Component {
  @service store;

  @arg search;
  @arg top;
  @arg skip;

  @tracked items = A([]);

  lastSearch = '';

  @restartableTask
  *queryItems() {
    if (isBlank(this.search)) {
      return (this.items = []);
    }

    if (this.search === this.lastSearch) {
      return this.items;
    }

    this.lastSearch = this.search;

    yield timeout(DEBOUNCE_MS);
    return yield this.storeQuery.perform();
  }

  @task({ drop: true })
  *storeQuery() {
    try {
      this.items = yield this.store.query('subscription', { $search: this.search, $top: this.top, $skip: this.skip });
    } catch (error) {
      console.error(error);
    }
  }
}
