import Service, { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';

import { storageFor } from 'ember-local-storage';
import { task } from 'ember-concurrency';

export default class SessionDataService extends Service {
  @service session;
  @service router;
  @service store;
  @service snackbar;
  @storageFor('session') sessionStorage;
  // @service provider;

  async impersonate(accountId, subscriptionId) {
    if (isEmpty(accountId)) return null;

    const account = await this.store.findRecord('account', accountId);

    if (!account) return null;

    const subscriptionsByAccount = await this.store.query('subscription', {
      accountId,
    });

    const toSubscription = isPresent(subscriptionId)
      ? subscriptionsByAccount.find((sub) => sub.id == subscriptionId)
      : subscriptionsByAccount.firstObject;

    this.sessionStorage.set('subscription.current', toSubscription.serialize({ includeId: true }));

    this.sessionStorage.set(
      'subscription.all',
      subscriptionsByAccount.map((sub) => sub.serialize({ includeId: true }))
    );

    // If the impersonated account is in the account.all list, the user is not using a client account
    const isImpersonated = !this.sessionStorage
      .get('account.all')
      .some((acc) => acc.parentId === account.accountNumber);

    this.sessionStorage.set('impersonated', isImpersonated);

    this.sessionStorage.set('account.current', account.serialize({ includeId: true }));

    await this.router.transitionTo('accounts.index', account.accountNumber);

    this.snackbar.show({ message: `Utilizando como: ${subscriptionId ? toSubscription.name : account.name}!` });

    // this.mountProviders(accountId);

    return account;
  }

  async setCurrentSubscription(subscription) {
    if (isEmpty(subscription)) return null;

    const account = await this.store.findRecord('account', subscription?.account?.id);
    const subscriptionsByAccount = await this.store.query('subscription', {
      accountId: subscription?.account?.id,
    });

    this.sessionStorage.set(
      'subscription.all',
      subscriptionsByAccount.map((sub) => sub.serialize({ includeId: true }))
    );

    this.sessionStorage.set('account.current', account.serialize({ includeId: true }));

    this.sessionStorage.set('subscription.current', subscription);

    return subscription;
  }

  get subscription() {
    if (!isPresent(this.sessionStorage.get('subscription.current'))) {
      this.router.transitionTo('login');
    }
    return this.sessionStorage.get('subscription');
  }

  get account() {
    if (!isPresent(this.sessionStorage.get('account.current'))) {
      this.router.transitionTo('login');
    }
    return this.sessionStorage.get('account');
  }

  get isImpersonated() {
    return this.sessionStorage.get('impersonated');
  }

  async getCurrentAccount() {
    if (!this.session.isAuthenticated) return null;

    if (this.session.isAuthenticated && isPresent(this.sessionStorage.get('account.current')))
      return this.sessionStorage.get('account.current');

    const subscriptionsByUser = await this.store.findAll('user/subscription');
    if (isEmpty(subscriptionsByUser) || !isPresent(subscriptionsByUser.firstObject.parentId)) return null;

    this.sessionStorage.set(
      'account.all',
      subscriptionsByUser.map((sub) => sub.serialize({ includeId: true }))
    );

    const account = await this.store.findRecord('account', subscriptionsByUser.firstObject.parentId);

    this.sessionStorage.set('account.current', account.serialize({ includeId: true }));

    const subscriptionsByAccount = await this.store.query('subscription', {
      accountId: account.accountNumber,
    });

    // Set default subscription as first position of array
    this.sessionStorage.set('subscription.current', subscriptionsByAccount.firstObject.serialize({ includeId: true }));

    this.sessionStorage.set(
      'subscription.all',
      subscriptionsByAccount.map((sub) => sub.serialize({ includeId: true }))
    );

    // this.mountProviders(account.id);

    return account;
  }

  @task({ drop: true })
  *handleCurrentAccount() {
    return yield this.getCurrentAccount();
  }

  async mountProviders(subscriptionId) {
    if (isEmpty(subscriptionId)) return;
    const currentAccountImpersonate = this.session.data.current_account_impersonate;
    const currentAccount = this.session.data.current_account;
    const account = currentAccountImpersonate ? currentAccountImpersonate : currentAccount;

    if (!isEmpty(account.providers)) {
      account.providers.map((provider) => {
        // this.provider.enable(provider);
      });
    } else {
      account.providers = [];
      const providersQuery = await this.store.query('provider', {
        adapterOptions: { subscriptionId: subscriptionId.replace('sub_', '') },
      });
      providersQuery.map((provider) => {
        if (provider.status == 'Active') {
          // this.provider.enable(provider.namespace);
          account.providers.addObject(provider.namespace);
        } else {
          // this.provider.disable(provider.namespace);
        }
      });
    }
    this.session.store.persist(this.session.data);
  }
}
