import BaseSessionService from 'ember-simple-auth/services/session';
import ENV from 'accounts-app/config/environment';
import { debug } from '@ember/debug';
import { service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class SessionService extends BaseSessionService {
  @service sessionData;
  @service user;
  @storageFor('settings') settings;
  @service router;
  @service implicitGrantAuthenticator;

  environmentConfigs = ENV['nfeio-ember-implicit-auth'];

  requireAuthentication(transition, routeOrCallback) {
    if (transition && transition.intent && transition.intent.url) this.settings.set('intentUrl', transition.intent.url);

    if (super.requireAuthentication) return super.requireAuthentication(transition, routeOrCallback);
  }

  async refreshAuthentication(callback) {
    return await this.implicitGrantAuthenticator._refreshToken(callback);
  }

  async authenticationSucceeded() {
    debug(`authentication: succeeded`);

    await this.user.manageLanguage();

    const intentUrl = this.settings.get('intentUrl');
    debug(`authentication: intent url ${intentUrl}`);
    if (intentUrl && intentUrl != 'undefined' && intentUrl != '/') {
      return intentUrl;
    }

    return 'index';
  }

  async invalidate(data) {
    await super.invalidate(data);
    window.localStorage.clear()
    return window.location.replace(
      `${this.environmentConfigs.host}${this.environmentConfigs.endsessionEnpoint}?clientId=${this.environmentConfigs.clientId}`
    );
  }

  get account() {
    return this.sessionData.handleCurrentAccount.perform();
  }
}
