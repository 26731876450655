import Service, { service } from '@ember/service';
// import { camelize } from '@ember/string';

export default class ProviderService extends Service {
  @service store;
  @service session;

  adapter = this.store.adapterFor('provider');

  constructor() {
    super(...arguments);
    this._providers = Object.create(null);
  }

  setup(providers) {
    this._resetProviders();
    for (let provider in providers) {
      if (providers.hasOwnProperty(provider)) {
        if (!!providers[provider]) {
          this.enable(provider);
        } else {
          this.disable(provider);
        }
      }
    }
  }

  enable(provider) {
    let normalizedProvider = this._normalizeProvider(provider);
    this._providers[normalizedProvider] = true;
    this.notifyPropertyChange(normalizedProvider);
  }

  disable(provider) {
    let normalizedProvider = this._normalizeProvider(provider);
    this._providers[normalizedProvider] = false;
    this.notifyPropertyChange(normalizedProvider);
  }

  async activate(subscriptionId, providerId) {
    const provider = await this.adapter.findRecord(providerId);
    if (provider.isActive) {
      await provider.setStatus('register', subscriptionId);
      this.enable(provider.namespace);
    }
  }

  async deactivate(subscriptionId, providerId) {
    const provider = await this.adapter.findRecord(providerId);
    if (provider.isActive) {
      await provider.setStatus('unregister', subscriptionId);
      this.enable(provider.namespace);
    }
  }

  isEnabled(provider) {
    return this._providerIsEnabled(provider);
  }

  _resetProviders() {
    this._providers = Object.create(null);
  }

  _providerIsEnabled(provider) {
    let normalizeFeature = this._normalizeProvider(provider);
    return this._providers[normalizeFeature] || false;
  }

  _normalizeProvider(provider) {
    return provider;
  }

  unknownProperty(key) {
    return this.isEnabled(key);
  }
}
