import BaseOdataSerializer from './base-odata';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default class AccountSerializer extends BaseOdataSerializer.extend(EmbeddedRecordsMixin) {
  primaryKey = 'accountNumber';
  attrs = {
    contacts: { embedded: 'always' },
    address: { embedded: 'always' },
  };

  _normalizeContact(id, type, phone, email) {
    return { id, type, email, phone };
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (!payload.value) {
      payload.contacts = [];

      payload.contacts.push(this._normalizeContact(1, 'administrator', payload.telephone1, payload.emailAddress1));
      payload.contacts.push(this._normalizeContact(2, 'billing', payload.telephone2, payload.emailAddress2));
      payload.contacts.push(this._normalizeContact(3, 'technical', payload.telephone3, payload.emailAddress3));

      delete payload.telephone1;
      delete payload.telephone2;
      delete payload.telephone3;
      delete payload.emailAddress1;
      delete payload.emailAddress2;
      delete payload.emailAddress3;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  serialize() {
    let json = super.serialize(...arguments);

    json.contacts.forEach((contact) => {
      json[`telephone${contact.id}`] = contact.phone;
      json[`emailAddress${contact.id}`] = contact.email;
    });

    delete json.contacts;

    return json;
  }

  serializeIntoHash(hash, type, record, options) {
    // remove modelName from payload
    return Object.assign(hash, this.serialize(record, options));
  }
}
