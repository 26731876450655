import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class EditContactDialog extends Component {
  @task
  *updateContact() {
    const { model, contact, closed } = this.args;

    let changeset = contact.changeset;

    yield changeset.validate();

    if (changeset.isInvalid) return;

    yield model.save();

    return closed();
  }

  @action
  close() {
    const { closed, contact } = this.args;

    if (contact.hasDirtyAttributes) {
      contact.rollbackAttributes();
    }

    closed();
  }

  @action
  setPhone({ detail }) {
    const { contact } = this.args;
    contact.phone = detail?.value;
  }

  @action
  changePhone(e) {
    const { contact } = this.args;
    contact.phone = e.detail.value;
    return this;
  }

  @action
  errorLog(e) {
    console.error(e.detail.code);
    return this;
  }
}
