import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';

export default class AgreementPrintRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId, agreementId }) {
    accountId = accountId ?? this.paramsFor('accounts').accountId;
    const agreement = await this.store.findRecord('agreement', agreementId, { adapterOptions: { accountId } });

    var agreementProducts = await this.store.query('agreement-product', { accountId, agreementId });
    var agreementProductPriceRates = await Promise.all(
      agreementProducts.map(async (item) => {
        try {
          item.productPriceRate = await this.store.queryRecord('product-price-rate', {
            priceLevelId: item.priceLevel.id,
            productId: item.product.id,
          });
        } catch (ex) {
          item.productPriceRate = null;
        }
        return item;
      })
    ).catch();

    return { agreement, items: agreementProductPriceRates };
  }
}
