import ApplicationAdapter from './application';
import ENV from 'accounts-app/config/environment';
import { service } from '@ember/service';

export default class ProviderAdapter extends ApplicationAdapter {
  @service user;
  host = ENV.APP.SIGNATURE_API_URL;
  namespace = 'accounts';

  get headers() {
    return this.user.authorizationHeader;
  }

  query(store, model, { adapterOptions }) {
    let url = `${this.host}/${this.namespace}/${adapterOptions.subscriptionId}/providers`;
    return super.ajax(url, 'GET', {
      headers: this.headers,
    });
  }

  register({ id, subscriptionId }) {
    const url = `${this.host}/${this.namespace}/${subscriptionId.replace('sub_', '')}/providers/${id}/register`;
    return this.ajax(url, 'POST');
  }

  unregister({ id, subscriptionId }) {
    const url = `${this.host}/${this.namespace}/${subscriptionId.replace('sub_', '')}/providers/${id}/unregister`;
    return this.ajax(url, 'POST');
  }
}
