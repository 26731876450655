import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class AccountsSubscriptionUsageController extends Controller {
  @service store;
  @service export;
  @service router;
  @service snackbar;
  @service intl;
  @service user;
  @service sessionData;

  queryParams = ['date'];

  @tracked date;

  maxDate = new Date().toJSON().slice(0, 7);

  get usageDate() {
    return this.date ?? this.maxDate;
  }

  @task
  *exportUsage(accountId, subscriptionId, usedOn) {
    let date = new Date(`${usedOn}-01 23:59:59.0000Z`);
    try {
      yield this.export.createNewExportRecord(date, accountId, subscriptionId);
      this.snackbar.show({ message: this.intl.t('usageSummary.newExport') });
      this.router.transitionTo('accounts.subscriptions.export', { accountId, subscriptionId });
    } catch (error) {
      console.error(error);
    }
  }

  @action
  setDate({ detail }) {
    this.date = detail?.value;
  }

  @action
  refreshPage() {
    window.location.reload(true);
  }
}
