import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class UserAccountsLoaderComponent extends Component {
  @service store;

  @tracked accounts;

  constructor(...args) {
    super(...args);
    this.loadAccounts.perform();
  }

  @task
  *loadAccounts() {
    this.accounts = yield this.store.findAll('user/subscription');
    // yield timeout(500);
  }
}
