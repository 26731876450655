import ApplicationAdapter from '../application';
import ENV from 'accounts-app/config/environment';
import { service } from '@ember/service';

export default class SubscriptionUserAdapter extends ApplicationAdapter {
  @service user;
  @service session;
  host = ENV.APP.SIGNATURE_API_URL;
  namespace = 'accounts';

  get headers() {
    return this.user.authorizationHeader;
  }

  async query(store, { modelName }, { adapterOptions }) {
    let url = `${this.host}/${this.namespace}/${adapterOptions.subscriptionId}/owners`;

    const { owners } = await super.ajax(url, 'GET', {
      headers: this.headers,
    });
    return { [modelName]: owners.map((e) => (e = { email: e })) ?? [] };
  }

  deleteRecord(store, model, { adapterOptions, id }) {
    let url = `${this.host}/${this.namespace}/${adapterOptions.subscriptionId}/owners/${id}`;

    return super.ajax(url, 'DELETE', {
      headers: this.headers,
    });
  }

  async createRecord(store, { modelName }, { adapterOptions, record }) {
    let url = `${this.host}/${this.namespace}/${adapterOptions.subscriptionId}/owners`;

    const { owner } = await super.ajax(url, 'POST', {
      headers: this.headers,
      data: {
        owner: record.email,
      },
    });

    return { [modelName]: owner };
  }
}
