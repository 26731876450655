import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isThursday } from 'date-fns';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NfeLayoutDrawerComponent extends Component {
  @service session;
  @service drawer;
  @service layout;
  @service sessionData;
  @service router;
  @service user;
  @service snackbar;
  // @service provider;

  @tracked
  isMenuOpen = false;

  get shouldShowTopAppBar() {
    return this.session.isAuthenticated;
  }

  get isPrint() {
    return this.router.currentURL ? this.router.currentURL.indexOf('/print') > 0 : false;
  }

  get account() {
    return {
      current: {
        parentId: this.sessionData.account.current.accountNumber,
        name: this.sessionData.account.current.name,
      },
    };
  }

  @action
  async logout() {
    await this.session.invalidate();
  }

  @action
  drawerToggle() {
    this.drawer.toggle();
  }

  @action
  async changeSubscription({ detail }) {
    if (detail?.option)
      await this.sessionData.impersonate(this.sessionData.account.current.accountNumber, detail?.option?.id);
  }

  @action
  async changeAccount({ detail }) {
    if (detail?.option?.parentId) await this.sessionData.impersonate(detail?.option?.parentId);
  }
}
