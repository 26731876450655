import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MainInvoicesController extends Controller {
  queryParams = ['top', 'skip', 'filter'];

  @service store;
  @service sessionData;

  @tracked filter = '';
  @tracked top = 10;
  @tracked skip = 0;

  @action
  async getPaymentUrl(accountId, invoiceId, ev) {
    if (ev) ev.stopPropagation();
    try {
      const adapter = this.store.adapterFor('payment');
      return await adapter.getPaymentUrl(accountId, invoiceId);
    } catch (error) {
      return new Error('Não foi possível visualizar tela de pagamento');
    }
  }

  @action
  onSearch(ev) {
    this.filter = ev.target.value;
  }

  @action
  nextPage() {
    this.skip += this.top;
  }

  @action
  previousPage() {
    this.skip -= this.top;
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = value;
  }

  @action
  refreshPage() {
    window.location.reload(true);
  }
}
